
import { Component, Vue } from "vue-property-decorator";
import { required } from "@/helpers/rules";
import { SegmentedButtonItem } from "vuelando";
import { subscriptionPlansService } from "@/services/api/subscription-plan.service";
import { PromotionPlans } from "@/models/entities/subscription-plan.interface";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import store from "@/store";
import { userService } from "@/services/api/user.service";
import OverlayModule from "@/store/modules/overlay.module";
import { paymentsService } from "@/services/api/payments.service";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);

@Component
export default class SubscriptionPlanOverview extends Vue {
  private required = required;
  private subscriptionPlans: PromotionPlans[] = [];
  private roleId = 0;
  private toggle = 0;
  private duration = 0;
  private downgrade = false;
  private subscriptionExpired = false;

  private subscriptionPlan: PromotionPlans = {
    offertID: 0,
    nameOffert: "",
    descrOffert: "",
    price: "",
    montlyBeneficiaries: "",
    totalSubjects: "",
    firstSupport: false,
    secondSupport: false,
    freeTrial: "",
    expiration: "",
    roleId: 0,
    priceMonthID: "",
    priceYearID: "",
  };

  private async created() {
    this.subscriptionPlans = (
      await subscriptionPlansService.GetAllOffert()
    ).data;
    this.roleId = authModule.profileRoleID;
    this.duration = (await userService.subscriptionDuration()).data;
    overlayModule.showOverlay();
    this.downgrade = (await paymentsService.getChangedPlan()).data;
    this.subscriptionPlan = (await userService.GetSubscription()).data;
    var parse = this.subscriptionPlan.expiration.split("/");
    this.subscriptionExpired =
      new Date(parse[1] + "/" + parse[0] + "/" + parse[2]).getTime() <
      new Date().getTime();
  }

  private back() {
    this.$emit("back");
  }

  private openConfirmPlan(plan) {
    this.$emit("openConfirmPlan", plan, this.toggle);
  }

  // private showPrice(search: number): boolean {
  //   const found = this.toggle === search);
  //   if (found !== null && found !== undefined) return found === search;
  //   return false;
  // }

  private buttons: SegmentedButtonItem[] = [
    {
      key: 0,
      iconName: "",
      text: this.$tc("payments.Monthly"),
    },
    {
      key: 1,
      iconName: "",
      text: this.$tc("payments.Annual"),
    },
  ];
}
